<template>
<div>
    <a id="show-btn" class="nav-link" @click="showModal">
        <i class="fas fa-th-list " style="color: #356dac;"></i>
        <span class="menu-text">Station Admin</span>
    </a>
    <b-modal
        ref="station-admin-modal"
        hide-footer title="Station Administration"
        size="lg"
        :scrollable="true"
    >
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Name</th>
                <th>Notes</th>
                <th>Calendars</th>
                <th>Edit</th>
                <th>Drop</th>
            </tr>
            </thead>
            <tbody v-if="stations && 0 <= stations.length">
                <tr v-for="station in stations">
                    <td>{{ station.name }}</td>
                    <td>{{ station.notes }}</td>
                    <td>
                        <ul
                            v-if="station.calendars.length > 0"
                            v-for="calendar in station.calendars"
                        >
                            <li>{{ calendar.name }}</li>
                        </ul>
                    </td>
                    <td>
                        <button class="btn btn-success" @click="editStation(station)">Edit</button>
                    </td>
                    <td>
                        <button class="btn btn-danger" @click="dropStation(station)">Drop</button>
                    </td>
                    <slot name="actions" v-bind:resource="station"></slot>
                </tr>
            </tbody>
            <tfoot>
            <b-button variant="success" @click.prevent="createStation()">New Station</b-button>
            <b-button variant="warning" @click.prevent="hideModal">Close</b-button>
            </tfoot>
        </table>
    </b-modal>

    <b-modal
        ref="station-create-modal"
        hide-footer
        size="lg"
        title="New Station"
    >
        <b-form  @reset="formReset">
            <input type="hidden" name="id" v-model="form.id">
            <b-form-group id="calendars" label="Station Calendars" label-for="calendars" v-slot="{ ariaDescribedBy }" v-if="mycalendars.length > 0">
                <b-form-checkbox-group
                    v-model="form.calendars"
                    :options="mycalendars"
                    :aria-describedby="ariaDescribedBy"
                >
                </b-form-checkbox-group>
            </b-form-group>

            <b-form-group id="name" label="Station Name" label-for="name">
                <b-form-input
                    id="input-name"
                    v-model="form.name"
                    placeholder="Enter name"
                    required
                ></b-form-input>
            </b-form-group>

            <b-form-group id="notes" label="Station Notes" label-for="notes">
                <b-form-input
                    id="input-notes"
                    v-model="form.notes"
                    placeholder="Enter notes"
                    required
                ></b-form-input>
            </b-form-group>
        </b-form>

        <b-button id="createStation" @click.prevent="onSubmit()" variant="primary" v-if="new_station === true">Submit</b-button>
        <b-button @click.prevent="updateStation()" variant="primary" v-if="new_station === false">Update</b-button>
        <b-button @click.prevent="formReset()" type="reset" variant="danger" v-if="new_station === true">Reset</b-button>
        <b-button variant="warning" @click="hideCreateModal()">Close</b-button>

    </b-modal>
</div>
</template>

<script>
export default {
    name: 'station-admin-modal',
    data() {
        return  {
            new_station: false,
            stations: [],
            mycalendars: [],
            selected: [],
            show: true,
            defaultCalendar: [],
            showStations: 0,
            form: {
                id: null,
                user_id: null,
                name: null,
                notes: null,
                calendars: [],
            }
        }
    },
    methods: {
        showModal() {
            // this.fetchData();
            this.$refs['station-admin-modal'].show();
        },
        hideModal() {
            this.$refs['station-admin-modal'].hide();
        },
        showCreateModal() {
            this.$refs['station-create-modal'].show();
        },
        hideCreateModal() {
            this.$refs['station-create-modal'].hide();
        },
        formReset() {
            this.form.id = null;
            this.form.name = null;
            this.form.user_id = null;
            this.form.notes = null;
            this.form.calendars = [];
        },
        fetchData() {
            return axios.get('/stations')
                .then(response => {
                    // console.log('response.data', response.data);
                    this.stations = response.data.stations;
                    return axios.get('/calendars')
                        .then(response => {
                            let cals = [];
                            let ids = [];
                            response.data.calendars.forEach(function(k,v) {
                                let cal = {
                                    'value': k.id,
                                    'text': k.name
                                };
                                cals.push(cal);
                                ids.push(cal.id)
                            });
                            this.mycalendars = cals;
                            this.form.calendars = ids;
                        });

                });
        },
        createStation(cal) {
            this.new_station = true;
            this.formReset();
            this.showCreateModal();

        },
        onSubmit() {
            let data = {
                _token: Laravel.csrfToken,
                name: this.form.name,
                notes: this.form.notes,
            }
            return axios.post('stations', data)
                .then(response => {
                    this.fetchData();
                    this.hideCreateModal();
                });
        },
        editStation(station) {
            this.new_station = false;
            this.formReset();
            return axios.get('/stations/' + station.id + '/edit')
                .then(response => {
                    // console.log('response.data',response.data.station);
                    this.form.id = response.data.station.id;
                    this.form.name = response.data.station.name;
                    this.form.notes = response.data.station.notes;
                    let cals = [];
                    response.data.station.calendars.forEach(function(c) {
                        cals.push(c.id);
                    });
                    this.form.calendars = cals;
                    this.showCreateModal();
                });
        },
        dropStation(station) {
            this.$swal({
                title: `Are you sure you want to delete Station: ${station.name}?`,
                text: "You won't be able to reverse this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    return axios.post('/stations/' + station.id + '/delete')
                        .then(response => {
                            console.log('response drop', response.data);
                            this.fetchData();
                        });
                }
            });

        },
        updateStation(station) {
            console.log('form', this.form);
            let data = {
                _token: Laravel.csrfToken,
                id: this.form.id,
                calendars: this.form.calendars,
                name: this.form.name,
                notes: this.form.notes,
            };
            return axios.post('/stations/' + this.form.id + '/update', data)
                .then(response => {
                    console.log('response', response);
                    this.hideCreateModal();
                    this.fetchData();
                });
        },
    },
    mounted: function mounted() {
        this.fetchData();
    }

}
</script>

<style scoped>

</style>
