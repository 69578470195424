<template>
    <div>
        <a id="show-btn" class="nav-link" @click="showModal">
            <i class="fas fa-calendar " style="color: #356dac;"></i>
            <span class="menu-text">Calendar Admin</span>
        </a>

        <b-modal
            ref="calendar-admin-modal"
            hide-footer title="Calendar Administration"
            size="lg"
            :scrollable="true"
        >
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Duration</th>
                    <th>MultiDay</th>
                    <th>Save</th>
                    <th>Drop</th>
                </tr>
                </thead>
                <tbody v-if="calendars && 0 < calendars.length">
                <tr v-for="calendar in calendars">
                    <td><input v-model="calendar.name" size="10"></td>
                    <td>
                        <b-form-timepicker
                            v-model="calendar.start"
                        ></b-form-timepicker>
                    </td>
                    <td>
                        <b-form-timepicker
                            v-model="calendar.end"
                        ></b-form-timepicker>
                    </td>
                    <td><input v-model="calendar.duration" size="10"></td>
                    <td><input type="checkbox" v-model="calendar.multiday"></td>
                    <slot name="columns" v-bind:resource="calendar"></slot>
                    <td>
                        <button class="btn btn-success" @click="saveCalendar(calendar)">Save</button>
                    </td>
                    <td>
                        <button class="btn btn-danger" @click="dropCalendar(calendar)">Drop</button>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                </tfoot>
            </table>
            <div class="pb-4">
                <b-button class="left" variant="success" @click="showCreateModal()">New Calendar</b-button>
                <b-button class="left" variant="warning" @click="hideModal()">Close</b-button>
            </div>
        </b-modal>

        <b-modal
            ref="calendar-create-modal"
            hide-footer
            size="sm"
            title="New Calendar"
        >
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="name" label="Calendar Name" label-for="name">
                    <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Enter name"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="start" label="Calendar Start Time" label-for="start">
                    <b-form-timepicker
                        id="input-start"
                        v-model="form.start"
                        placeholder="Enter start time"
                        required
                    ></b-form-timepicker>
                </b-form-group>


                <b-form-group id="end" label="Calendar End Time" label-for="end">
                    <b-form-timepicker
                        id="input-end"
                        v-model="form.end"
                        placeholder="Enter end time"
                        required
                    ></b-form-timepicker>
                </b-form-group>

                <b-form-group id="duration" label="Calendar Divisions" label-for="end">
                    <b-form-select
                        v-model="form.duration"
                        :options="duroptions"
                        id="input-duration"
                        required
                    ></b-form-select>
                </b-form-group>

                <b-form-checkbox
                    id="input-multiday"
                    v-model="form.multiday"
                    name="input-multiday"
                    value="1"
                    unchecked-value="0"
                    >Does this calendar span go past midnight?</b-form-checkbox>

                <b-button type="submit" variant="primary">Submit</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button variant="warning" @click="hideCreateModal">Close</b-button>

            </b-form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'calendar-admin-modal',
    data() {
        return {
            resourceUrl: '/calendars',
            calendars: [],
            show: true,
            form: {
                name: null,
                start: null,
                end: null,
                duration: null,
                multiday: 0,
            },
            duroptions: [
                { value: null, text: 'Please select the duration' },
                { value: '00:30', text: '30 Minutes'},
                { value: '01:00', text: '1 Hour'}
            ]
        }
    },
    methods: {
        showModal() {
            console.log('showModal activated');
            this.fetchData();
            this.$refs['calendar-admin-modal'].show();

        },
        hideModal() {
            this.$refs['calendar-admin-modal'].hide();
            window.location.reload();
        },
        showCreateModal() {
            this.$refs['calendar-create-modal'].show();
        },
        hideCreateModal() {
            this.$refs['calendar-create-modal'].hide();
        },
        saveCalendar(cal) {
            let url = this.resourceUrl + '/' + cal.slug + '/edit';
            let data = {
                'start': cal.start,
                'end': cal.end,
                'name': cal.name,
                'duration': cal.duration,
                'multiday': cal.multiday,
            };
            console.log('saveCalendar data',data);
            return axios.post(url, data)
                .then(response => {
                    console.log('saveCalendar response', response);
                    if (response.data) {
                        this.$swal(
                            'Saved!',
                            `${cal.name} has been updated.'`,
                            'success'
                        );

                    }
                });
        },
        dropCalendar(cal) {
            console.log('drop cal', cal);
            let url = this.resourceUrl + '/' + cal.id + '/drop';
            let data = {
                _token: Laravel.crsfToken,
                id: cal.id
            };
            this.$swal({
                title: `Are you sure you want to delete Shift: ${cal.name}?`,
                text: "You won't be able to reverse this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.$swal(
                        'Deleted!',
                        `${cal.name} has been deleted.'`,
                        'success'
                    )
                    return axios.post(url, data)
                        .then((response) => {
                            this.calendars = response.data.calendars;
                            this.fetchData();
                        });
                }
            });
        },
        onSubmit(event) {
            event.preventDefault();
            let data = {
                _token: Laravel.csrfToken,
                start: this.form.start,
                end: this.form.end,
                name: this.form.name,
                duration: this.form.duration,
                multiday: this.form.multiday,
            };
            console.log('onsubmit data', data);
            return axios.post(this.resourceUrl, data)
                .then(response => {
                    console.log('onsubmit response', response);
                    this.fetchData();
                    this.hideCreateModal();
                });
        },
        onReset(event) {
            event.preventDefault();
            this.form.name = null;
            this.form.start = null;
            this.form.end = null;
            this.form.duration = null;
            this.form.multiday = null;
        },
        fetchData() {
            return axios.get(this.resourceUrl)
                .then(response => {
                    console.log('calendar fetchData response', response.data);
                    this.calendars = response.data.calendars;
                });

        },

    },
    mounted: function mounted() {

    }

}
</script>

<style scoped>

</style>
