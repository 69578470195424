/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('../../node_modules/jqueryui/jquery-ui.min');
require('../../node_modules/jqueryui/jquery-ui.css');
import '../../node_modules/bootstrap-colorpicker/dist/js/bootstrap-colorpicker';
require('./fcalendar');
import '../../node_modules/fullcalendar/main.css';
window.Vue = require('vue');
import axios from '../../node_modules/axios';
window.axios = axios;
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueEvents from 'vue-events';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Photoshop from 'vue-color';
import 'verte/dist/verte.css';
Vue.use(Photoshop);
Vue.use(BootstrapVue);
Vue.use(VueEvents);
Vue.use(VueSweetalert2);
// Vue.component('v-select', vSelect);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('team-admin-modal', require('./components/team-admin-modal').default);
Vue.component('shift-admin-modal', require('./components/shift-admin-modal').default);
Vue.component('calendar-admin-modal', require('./components/calendar-admin-modal').default);
Vue.component('station-admin-modal', require('./components/station-admin-modal').default);
Vue.component('stations-dropdown', require('./components/stations-dropdown').default);
Vue.component('shifts-index', require('./components/shifts-index').default);
Vue.component('hours-index', require('./components/hours-index').default);
// Vue.component('active-calendars', require('./components/active-calendars').default);
// Vue.component('fullcalendar', require('./components/fullcalendar').default);
Vue.config.productionTip = false;
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

let app = new Vue({
    el: '#app',
});
