<template>
    <div>
        <a id="show-btn" class="nav-link" @click="showModal">
            <i class="fas fa-th-list " style="color: #356dac;"></i>
            <span class="menu-text">Shift Admin</span>
        </a>

        <b-modal
            ref="shift-admin-modal"
            hide-footer title="Shift Administration"
            size="lg"
            :scrollable="true"
        >
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>Member</th>
                    <th>Station</th>
                    <th>Notes</th>
                    <th>Duration</th>
                    <th>Edit</th>
                    <th>Drop</th>
                </tr>
                </thead>
                <tbody v-if="shifts && 0 <= shifts.length">
                <tr v-for="shift in shifts">
                    <td>{{ shift.member.name }}</td>
                    <td>{{ shift.station }}</td>
                    <td>{{ shift.notes }}</td>
                    <td>{{ shift.duration }}</td>
                    <slot name="columns" v-bind:resource="shift"></slot>
                    <td>
                        <button class="btn btn-success" @click="editShift(shift)">Edit</button>
                    </td>
                    <td>
                        <button class="btn btn-danger" @click="dropShift(shift)">Drop</button>
                    </td>
                    <slot name="actions" v-bind:resource="shift"></slot>
                </tr>
                </tbody>
                <tfoot>
                    <b-button variant="success" @click.prevent="createShift()">New Shift</b-button>
                    <b-button variant="warning" @click.prevent="hideModal">Close</b-button>
                </tfoot>
            </table>
        </b-modal>

        <b-modal
            ref="shift-create-modal"
            hide-footer
            size="lg"
            title="New Shift"
            >
            <b-form @submit="onSubmit" @reset="formReset" v-if="show">
                <input type="hidden" name="id" v-model="form.id">
                <div class="form-group">
                    <label for="memberSelect">Shift Member</label>
                    <select
                        class="form-control"
                        id="memberSelect"
                        name="member_id"
                        v-model="form.member_id"
                    >
                        <option value="0">Please select a member</option>
                        <option
                            v-for="member in members"
                            :value="member.id"
                        >{{ member.name }}</option>
                    </select>
                </div>

                <b-form-group id="station" label="Shift Station" label-for="station">
                    <b-form-select
                        id="input-station"
                        v-model="form.station"
                        :options="stations"
                        required
                    ></b-form-select>
                </b-form-group>

                <b-form-group id="duration" label="Shift Duration" label-for="duration">
                    <b-form-select
                        id="input-duration"
                        v-model="form.duration"
                        :options="durations"
                        required
                    ></b-form-select>
                </b-form-group>

                <b-form-group id="notes" label="Shift Notes" label-for="notes">
                    <b-form-input
                        id="input-notes"
                        v-model="form.notes"
                        placeholder="Enter notes"
                        required
                    ></b-form-input>
                </b-form-group>

                <b-form-checkbox
                    id="input-display"
                    v-model="form.display"
                    value="1"
                    unchecked-value="0"
                >
                    Display in side menu
                </b-form-checkbox>

                <b-form-checkbox
                    id="input-recurring"
                    v-model="form.recurring"
                    value="1"
                    unchecked-value="0"
                >
                    This shift is recurring!
                </b-form-checkbox>

                <b-form-group id="days" label="Shift Days" label-for="days" v-if="form.recurring > 0">
                    <b-form-checkbox
                        v-model="form.on_sunday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Sunday
                    </b-form-checkbox>

                    <b-form-checkbox
                        v-model="form.on_monday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Monday
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="form.on_tuesday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Tuesday
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="form.on_wednesday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Wednesday
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="form.on_thursday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Thursday
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="form.on_friday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Friday
                    </b-form-checkbox>
                    <b-form-checkbox
                        v-model="form.on_saturday"
                        value="1"
                        unchecked="0"
                        inline
                    >
                        Saturday
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group label="Shift Times" v-if="form.recurring > 0">
                    <b-form-timepicker
                        id="input-start_time"
                        v-model="form.start_time"
                        placeholder="Enter shift start time"
                    ></b-form-timepicker>

                    <b-form-timepicker
                        id="input-end_time"
                        v-model="form.end_time"
                        placeholder="Enter shift end time"
                    ></b-form-timepicker>
                </b-form-group>

                <b-form-group label="Recurring Date Span" v-if="form.recurring > 0">
                    <b-form-datepicker
                        id="input-start_recur"
                        v-model="form.start_recur"
                        placeholder="Enter start recurring date"
                    ></b-form-datepicker>

                    <b-form-datepicker
                        id="input-end_recur"
                        v-model="form.end_recur"
                        placeholder="Enter end recurring date"
                    ></b-form-datepicker>
                </b-form-group>

                <b-button id="shiftCreate" @click.prevent="onSubmit" variant="primary" v-if="new_shift === true">Submit</b-button>
                <b-button @click.prevent="saveShift" variant="primary" v-if="new_shift === false">Update</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
                <b-button variant="warning" @click="hideCreateModal">Close</b-button>
            </b-form>

    </b-modal>

    </div>

</template>

<script>
import StationsDropdown from "./stations-dropdown";
export default {
    name: 'shift-admin-modal',
    data () {
        return {
            new_shift: true,
            shifts: [],
            members: [],
            memberOptions: [],
            resourceUrl: '/shifts',
            show: true,
            form: {
                id: null,
                member_id: null,
                station: null,
                duration: null,
                notes: null,
                recurring: 0,
                on_sunday: 0,
                on_monday: 0,
                on_tuesday: 0,
                on_wednesday: 0,
                on_thursday: 0,
                on_friday: 0,
                on_saturday: 0,
                start_recur: null,
                end_recur: null,
                start_time: null,
                end_time: null,
                display: 0,

            },
            days: [
                { value: '0', text: 'Sunday', model: 'on_sunday'},
                { value: '1', text: 'Monday', model: 'on_monday'},
                { value: '2', text: 'Tuesday',  model: 'on_tuesday'},
                { value: '3', text: 'Wednesday',  model: 'on_wednesday'},
                { value: '4', text: 'Thursday',  model: 'on_thursday'},
                { value: '5', text: 'Friday',  model: 'on_friday'},
                { value: '6', text: 'Saturday',  model: 'on_saturday'},
            ],
            stations: [],
            durations: [
                { text: 'Select a Duration', value: null },
                { text: '1 Hour', value: "01:00" },
                { text: '2 Hours', value: "02:00" },
                { text: '3 Hours', value: "03:00" },
                { text: '4 Hours', value: '04:00' },
                { text: '5 Hours', value: "05:00" },
                { text: '6 Hours', value: "06:00" },
                { text: '7 Hours', value: "07:00" },
                { text: '8 Hours', value: "08:00" },
                { text: '9 Hours', value: '09:00'},
                { text: '9 1/2 Hours', value: '09:30'},
                { text: '10 Hours', value: "10:00" },
                { text: '11 Hours', value: "11:00" },
                { text: '12 Hours', value: '12:00' }
            ],
            selected: [],
        }
    },

    methods: {
        onSubmit() {
            let data = {
                _token: Laravel.csrfToken,
                member_id: this.form.member_id,
                station: this.form.station,
                duration: this.form.duration,
                notes: this.form.notes,
                recurring: this.form.recurring,
                on_sunday: this.form.on_sunday,
                on_monday: this.form.on_monday,
                on_tuesday: this.form.on_tuesday,
                on_wednesday: this.form.on_wednesday,
                on_thursday: this.form.on_thursday,
                on_friday: this.form.on_friday,
                on_saturday: this.form.on_saturday,
                start_recur: this.form.start_recur,
                end_recur: this.form.end_recur,
                start_time: this.form.start_time,
                end_time: this.form.end_time,
                display: this.form.display,
            }
            // console.log('data',data, 'selected', this.selected);
            return axios.post(this.resourceUrl, data)
                .then(response => {
                    // console.log('response', response);
                    this.formReset();
                    this.hideCreateModal();
                });

        },
        formReset() {
            this.form.id = null;
            this.form.duration = null;
            this.form.notes = null;
            this.form.member_id = null;
            this.form.station = null;
            this.form.recurring = 0;
            this.form.on_sunday = 0;
            this.form.on_monday = 0;
            this.form.on_tuesday = 0;
            this.form.on_wednesday = 0;
            this.form.on_thursday = 0;
            this.form.on_friday = 0;
            this.form.on_saturday = 0;
            this.form.start_recur = null;
            this.form.end_recur = null;
            this.form.start_time = null;
            this.form.end_time = null;
            this.form.display = 0;
        },
        showModal() {
            this.fetchData();
            this.$refs['shift-admin-modal'].show();
        },
        hideModal() {
            this.$refs['shift-admin-modal'].hide();
        },
        showCreateModal() {
            this.$refs['shift-create-modal'].show();
        },
        hideCreateModal() {
            this.$events.fire('reloadData', true);
            this.fetchData();
            this.formReset();
            this.new_shift = false;
            this.$refs['shift-create-modal'].hide();
        },
        fetchData() {
            return axios.get(this.resourceUrl)
                .then(response => {
                    this.shifts = response.data.shifts;
                    this.memberOptions = response.data.members;
                    this.members = response.data.members;
                    let stats = [];
                    return axios.get('/stations')
                        .then(response => {
                            console.log('resp.data', response.data.stations);
                            $.each(response.data.stations, function(s, stat) {
                                stats.push({
                                    value: stat.name,
                                    text: stat.name
                                });
                            });
                            this.stations = stats;
                        });
                });

        },
        createShift() {
            this.formReset();
            this.new_shift = true;
            this.showCreateModal();
        },
        editShift(shift) {
            // console.log('shift',shift);
            let url = '/shifts/' + shift.id + '/edit';
            this.new_shift = false;
            this.formReset();
            return axios.get(url)
                .then(response => {
                    // console.log('edit response', response.data.shift);
                    this.form = response.data.shift;
                    this.showCreateModal();
                });
        },
        saveShift(event) {
            let url = '/shifts/' + this.form.id + '/update';
            console.log('form', this.form);
            let data = {
                _token: Laravel.csrfToken,
                member_id: this.form.member_id,
                notes: this.form.notes,
                duration: this.form.duration,
                station: this.form.station,
                recurring: this.form.recurring,
                on_sunday: this.form.on_sunday,
                on_monday: this.form.on_monday,
                on_tuesday: this.form.on_tuesday,
                on_wednesday: this.form.on_wednesday,
                on_thursday: this.form.on_thursday,
                on_friday: this.form.on_friday,
                on_saturday: this.form.on_saturday,
                start_recur: this.form.start_recur,
                end_recur: this.form.end_recur,
                start_time: this.form.start_time,
                end_time: this.form.end_time,
                display: this.form.display,
            };
            this.formReset();
            return axios.post(url, data)
                .then((response) => {
                    // console.log('response.data', response.data);
                    this.$events.fire('reloadData', true);
                    this.fetchData();
                    this.hideCreateModal();
                });

        },
        dropShift(shift) {
            let url = '/shifts/' + shift.id + '/drop';
            let data = {
                _token: Laravel.csrfToken,
                id: shift.id
            };
            this.$swal({
                title: `Are you sure you want to delete Shift: ${shift.notes}?`,
                text: "You won't be able to reverse this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    this.$swal(
                        'Deleted!',
                        `${shift.notes} has been deleted.'`,
                        'success'
                    )
                    return axios.post(url, data)
                        .then((response) => {
                            this.shifts = response.data.shifts;
                            this.fetchData();
                            this.$events.fire('reloadData', true);
                        });
                }
            });
        }
    },
}
</script>

<style scoped>

</style>
