<template>
<div>
        <b-form-select
            id="input-station"
            :options="stations"
            required
        ></b-form-select>
</div>
</template>

<script>
export default {
    name: "stations-dropdown",
    data() {
        return {
            stations: [],
            model: 'form.station'
        }
    },
    methods: {
        fetchData() {
            return axios.get('/stations/33c33870-8f27-11eb-8182-f3a99164fd75/get')
                .then(response => {
                    console.log('response', response.data.stations);
                    let stations = [];
                    stations.push({ text: 'Select a Station', value: null });
                   $.each(response.data.stations, function(s, station) {
                       stations.push({
                           text: station.name,
                           value: station.slug,
                       });
                   });
                   console.log('stations',stations);
                   this.stations = stations;
                });
        }
    },
    mounted: function mounted() {
        console.log('stations-index loaded');
        this.fetchData();
    },
}
</script>

<style scoped>

</style>
