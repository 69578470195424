<template>
    <div class="container p-0 m-0">
        <div id="external-events">
            <div class="row p-0 m-0" v-for="shift in shifts">
                <div v-if="shift.member.name != null">
                <button class="my-item btn btn-sm small border-1 p-0 m-0"
                    :data-title="shift.member.name"
                    :data-bgcolor="shift.member.bgcolor"
                    :data-textcolor="shift.member.textcolor"
                    :data-member_id="shift.member_id"
                    :data-duration="shift.duration"
                    :data-notes="shift.notes"
                    :data-station="shift.station"
                    :data-recurring="shift.recurring"
                    :data-on_sunday="shift.on_sunday"
                    :data-on_monday="shift.on_monday"
                    :data-on_tuesday="shift.on_tuesday"
                    :data-on_wednesday="shift.on_wednesday"
                    :data-on_thursday="shift.on_thursday"
                    :data-on_friday="shift.on_friday"
                    :data-on_saturday="shift.on_saturday"
                    :data-start_time="shift.start_time"
                    :data-end_time="shift.end_time"
                    :data-start_recur="shift.start_recur"
                    :data-end_recur="shift.end_recur"
                    :data-shift_id="shift.id"

                >
                    <span class="p-0 m-0" style="font-size:smaller;">{{ shift.member.name }} - {{ shift.station }} - {{ shift.duration }} - {{ (shift.recurring) ? 'Recur' : 'Single' }}</span>

                </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vueButton from 'vue-button';
import shiftAdminModal from './shift-admin-modal';
export default {
    components: {
        vueButton,
        shiftAdminModal,
    },
    name: "shifts-index",
    data() {
        return {
            shifts: {},
            resourceUrl: '/shifts',
        }
    },
    events: {
        reloadData(load) {
            this.fetchData();
        }
    },
    methods: {
        fetchData() {
            return axios.get(this.resourceUrl)
                .then((response) => {
                    // console.log('data', response.data.shifts);
                    let shifts = [];
                    response.data.shifts.forEach(function(shift) {
                        if (shift.display === 1) {
                            shifts.push(shift);
                        }
                    });
                    this.shifts = shifts;
                });

        },
    },
    mounted: function mounted() {
        this.fetchData();

    }
}
</script>

<style scoped>

</style>
