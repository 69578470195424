<template>
    <div>
        <a id="show-btn" class="nav-link" @click="showModal">
            <i class="fas fa-users " style="color: #356dac;"></i>
            <span class="menu-text">Team Admin</span>
        </a>

        <b-modal
            ref="team-admin-modal"
            hide-footer title="Team Administration"
            size="lg"
            :scrollable="true"
        >
            <table class="table-striped w-100">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Color</th>
                    <th>Calendars</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody v-if="0 < members.length">
                    <tr v-for="member in members">
                        <td>{{ member.name }}</td>
                        <td>{{ member.email }}</td>
                        <td>{{ member.phone }}</td>
                        <td>
                            <verte
                                :value="member.bgcolor"
                                menuPosition="left"
                            ></verte>
                        </td>
                        <td>
                            <ul v-if="member.calendars.length > 0">
                                <li v-for="calendar in member.calendars">{{ calendar.name }}</li>
                            </ul>
                        </td>
                        <td class="flex-container">
                            <button class="btn btn-success left" @click="editMember(member)">Edit</button>
                            <button class="btn btn-danger left" @click="dropMember(member)">Drop</button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                </tfoot>
            </table>
            <div class="pb-4">
                <b-button class="left" variant="success" @click="showCreateModal()">New Member</b-button>
                <b-button class="left" variant="warning" @click="hideModal">Close</b-button>

            </div>
        </b-modal>

        <b-modal
            ref="member-create-modal"
            hide-footer
            size="sm"
            title="New Member"
        >
            <b-form @reset="formReset" v-if="show">
                <input type="hidden" name="id" v-model="form.id">
                <b-form-group id="calendars" label="Member Calendars" label-for="calendars" v-slot="{ ariaDescribedBy }" v-if="mycalendars.length > 0">
                    <b-form-checkbox-group
                        v-model="form.calendars"
                        :options="mycalendars"
                        :aria-describedby="ariaDescribedBy"
                        >
                    </b-form-checkbox-group>
                </b-form-group>

                <b-form-group id="name" label="Name" label-for="name">
                    <b-form-input
                        id="input-name"
                        v-model="form.name"
                        placeholder="Enter name"
                        required
                    ></b-form-input>

                </b-form-group>
                <b-form-group id="email" label="Email" label-for="email">
                    <b-form-input
                        id="input-email"
                        v-model="form.email"
                        placeholder="Enter email"
                        required
                    ></b-form-input>
                </b-form-group>
                <b-form-group id="phone" label="Phone" label-for="phone">
                    <b-form-input
                        id="input-phone"
                        v-model="form.phone"
                        placeholder="Enter phone"
                        required
                    ></b-form-input>
                </b-form-group>

                <div class="row">
                    <div class="col-sm-6">
                        <b-form-group id="bgcolor" label="Member Color" label-for="bgcolor">
                            <verte
                                v-model="form.bgcolor"
                                picker="wheel"
                                :value="colors"
                                value="form.bgcolor"
                                menuPosition="top"
                            ></verte>
                        </b-form-group>
                    </div>
                    <div class="col-sm-6">
                        <b-form-group id="textcolor" label="Text Color" label-for="textcolor">
                            <verte
                                v-model="form.textcolor"
                                :value="colors"
                                picker="wheel"
                                value="form.textcolor"
                                menuPosition="top"
                            ></verte>
                        </b-form-group>
                    </div>
                </div>

                <b-button @click.prevent="onSubmit()" variant="primary" v-if="new_member === true">Create</b-button>
                <b-button @click.prevent="saveMember()" variant="primary" v-if="new_member === false">Update</b-button>
                <b-button type="reset" variant="danger">Reset</b-button>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import Select2 from 'v-select2-component';
import Chrome from 'vue-color';
import Photoshop from 'vue-color';
import Colourpicker from 'vue-colour-picker';
import verte from 'verte';
export default {
    components: {
        Select2,
        'colour-picker': Colourpicker,
        'chrome-picker': Chrome,
        'photoshop-picker': Photoshop,
        verte,
    },
    name: "team-admin-modal",
    data () {
        return {
            resourceUrl: '/members',
            members: [],
            mycalendars: [],
            selected: [],
            show: true,
            new_member: true,
            form: {
                id: null,
                name: null,
                email: null,
                phone: null,
                bgcolor: null,
                textcolor: null,
                calendars: [],
            },
            colors: {
                hex: '#194d33',
                hex8: '#194D33A8',
                hsl: { h: 150, s: 0.5, l: 0.2, a: 1 },
                hsv: { h: 150, s: 0.66, v: 0.30, a: 1 },
                rgba: { r: 25, g: 77, b: 51, a: 1 },
                a: 1
            },
            updateValue: '',
            isOpen: false,

        }
    },
    methods: {
        showModal() {
            this.$refs['team-admin-modal'].show();

        },
        hideModal() {
            this.$refs['team-admin-modal'].hide();
        },
        showCreateModal() {
            this.$refs['member-create-modal'].show();
        },
        hideCreateModal() {
            this.$refs['member-create-modal'].hide();
        },
        toggle: function() {
            this.isOpen = !this.isOpen;
            this.colors.source = 'hex';
        },
        onSubmit(e) {
            console.log('this.form.calendars',this.form.calendars);
            let data = {
                '_token': Laravel.csrfToken,
                'name': this.form.name,
                'email': this.form.email,
                'phone': this.form.phone,
                'bgcolor': this.form.bgcolor,
                'textcolor': this.form.textcolor,
            };
            if (typeof this.form.calendars[0] === 'undefined') {
                data.calendars = null;
            }
            console.log('t data', data);
            return axios.post(this.resourceUrl, data)
                .then((response) => {
                    // console.log('submitResponse', response);
                    this.fetchData();
                    this.hideCreateModal();
                });

        },
        fetchData() {
            return axios.get(this.resourceUrl)
                .then((response) => {
                    this.members = response.data.members;

                    return axios.get('/calendars')
                        .then(response => {
                            let cals = [];
                            let ids = [];
                            response.data.calendars.forEach(function(k,v) {
                                let cal = {
                                    'value': k.id,
                                    'text': k.name
                                };
                                cals[v] = cal;
                                ids[v] = cal.id;
                            });
                            this.mycalendars = cals;
                            this.form.calendars = ids;
                        });
                });

        },
        formReset() {
            this.form.id = null;
            this.form.name = null;
            this.form.email = null;
            this.form.phone = null;
            this.form.bgcolor = null;
            this.form.textcolor = null;
            this.form.calendars = [];
        },
        editMember(member) {
            this.new_member = false;
            this.formReset();
            return axios.get('/members/' + member.id + '/edit')
                .then(response => {
                    this.form.id = response.data.member.id;
                    this.form.name = response.data.member.name;
                    this.form.email = response.data.member.email;
                    this.form.phone = response.data.member.phone;
                    this.form.bgcolor = response.data.member.bgcolor;
                    this.form.textcolor = response.data.member.textcolor;
                    let cals = [];
                    response.data.member.calendars.forEach(function(c) {
                        cals.push(c.id);
                    });
                    this.form.calendars = cals;
                    this.showCreateModal();
                });
        },
        saveMember(member) {

            let url = this.resourceUrl + '/' + this.form.id + '/update';

            let data = {
                _token: Laravel.csrfToken,
                id: this.form.id,
                name: this.form.name,
                email: this.form.email,
                phone: this.form.phone,
                bgcolor: this.form.bgcolor,
                textcolor: this.form.textcolor,
                calendars: this.form.calendars,
            };
            return axios.post(url, data)
                .then((response) => {
                    this.fetchData();
                    this.hideCreateModal();
                });
        },
        dropMember(member) {
            let url = '/members/' + member.id + '/drop';
            let data = {
                _token: Laravel.csrfToken,
                id: member.id
            };
                this.$swal({
                    title: `Are you sure you want to delete Member: ${member.name}?`,
                    text: "You won't be able to reverse this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                if (result.value) {
                    return axios.post(url, data)
                        .then((response) => {
                            this.fetchData();
                        })
                }
            });

        }
    },
    mounted: function mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>
.window-header {
    background-color: #7aa6da;
}
.card-body {
    padding: 0;
}
.left {
    float: left;
}

.right {
    float: right;
}
ul {
    list-style-type: none;
}
.flex-container{
    display:flex;
    flex-direction:row;
    border:0px blue solid;
    /*justify-content:space-between;*/
}

</style>
