<template>
    <div>
        <div>
            <b-button @click="loadEvents" variant="primary" size="lg">Compute Weekly Hours</b-button>
        </div>

        <div  v-if="hours && 0 <= hours.length">
        <table class="table table-striped">
            <thead>
            <tr>
                <th>Member</th>
                <th>Hours</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="event in hours">
                <td>{{ event.employee }}</td>
                <td>{{ event.duration }}</td>
            </tr>
            </tbody>
        </table>
        </div>
    </div>

</template>

<script>
import moment from 'moment';
export default {
    name: "hours-index",
    data() {
        return {
            resourceUrl: '/calendars/events/',
            hours: [],
        }

    },
    methods: {
        loadEvents() {
            let value = $('#weekStart').val().getTime();
            const params = new URLSearchParams([['start', $('#weekStart').val().toISOString()], ['end', moment(value).add(7, 'days').subtract(1, 'second').toISOString()]]);
            return axios.get(this.resourceUrl + $('#calendarSlug').val(), { params })
                .then(response => {
                    console.log('resp.data', response.data);
                    let events = [];
                    $.each(response.data, function(k, v) {
                        events[k] = {};
                        for (const [key, value] of Object.entries(v)) {
                            if (key === 'employee') {
                                events[k]['employee'] = value;
                            }
                            if (key === 'start') {
                                events[k]['start'] = value;
                            }
                            if (key === 'end') {
                                events[k]['end'] = value;
                            }
                            let a = moment(events[k]['start']);
                            let b = moment(events[k]['end']);
                            events[k]['duration'] = b.diff(a, 'minutes') / 60;
                        }
                        delete events[k]['start'];
                        delete events[k]['end'];
                    });
                    // this.events = events;
                    let hours = [];
                    events.forEach(function(event) {
                        let existing = hours.filter(function(v) {
                            return v.employee === event.employee;
                        });
                        if (existing.length) {
                            let ndx = hours.indexOf(existing[0]);
                            hours[ndx].duration = parseFloat(hours[ndx].duration) + parseFloat(event.duration);
                        }
                        else {
                            hours.push(event)
                        }
                    });
                    this.hours = hours;
                });
        }
    },
    mounted() {
        console.log('hrs loaded');
    }
}
</script>

<style scoped>

</style>
