import {Calendar} from "@fullcalendar/core";
import dragula from "dragula";
import interactionPlugin, {ThirdPartyDraggable} from "@fullcalendar/interaction";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "@fullcalendar/moment";
import rrulePlugin from "@fullcalendar/rrule";

let TNGcal = {};

TNGcal.showCalendar = function(cal, events, gotoDate) {
    // console.log('gotoDate', gotoDate);
    // console.log('datesSet', cal.getDate());
    let slotMin = cal.start;
    let slotMax = cal.end;
    if (cal.start.length === 5) {
        slotMin = cal.start + ':00';
    }
    if (cal.end.length === 5) {
        slotMax = cal.end + ':00';
    }

    let drop = true;
    let select = true;
    let edit = true;
    let initView = 'timeGridWeek';
    let right = 'dayGridMonth,timeGridWeek timeGridDay,listWeek';
    let left = 'prev,next today';

    if (window.location.pathname !== '/home') {
        drop = false;
        select = false;
        edit = false;
        initView = 'dayGridMonth';
        right = 'dayGridMonth,listWeek';
        left = 'prev,next';
    }

    if (cal.multiday) {
        slotMax = 24 + parseInt(cal.end.split(':')) + ':00';
    }

    let calendarEl = document.getElementById('calendar');
    let containerEl = document.getElementById('external-events');

    let drake = dragula({
        containers: [ containerEl, calendarEl],
        copy: true
    });

    new ThirdPartyDraggable(containerEl, {
        itemSelector: '.my-item',
        mirrorSelector: '.gu-mirror', // the dragging element that dragula renders
        eventData: function(eventEl) {
            return {
                duration: eventEl.getAttribute('data-duration'),
                title: eventEl.getAttribute('data-title'),
                extendedProps: {
                    extendedProp: {
                        station: eventEl.getAttribute('data-station'),
                        notes: eventEl.getAttribute('data-notes'),
                        member_id: eventEl.getAttribute('data-member_id'),
                        bgcolor: eventEl.getAttribute('data-bgcolor'),
                        textColor: eventEl.getAttribute('data-textcolor'),
                        startRecur: eventEl.getAttribute('data-start_recur'),
                        endRecur: eventEl.getAttribute('data-end_recur'),
                        recurring: eventEl.getAttribute('data-recurring'),
                        on_sunday: eventEl.getAttribute('data-on_sunday'),
                        on_monday: eventEl.getAttribute('data-on_monday'),
                        on_tuesday: eventEl.getAttribute('data-on_tuesday'),
                        on_wednesday: eventEl.getAttribute('data-on_wednesday'),
                        on_thursday: eventEl.getAttribute('data-on_thursday'),
                        on_friday: eventEl.getAttribute('data-on_friday'),
                        on_saturday: eventEl.getAttribute('data-on_saturday'),
                        shift_id: eventEl.getAttribute('data-shift_id'),
                    }
                }
            }
        }
    });

    let calendar = new Calendar(calendarEl, {
        headerToolbar: {
            left: left,
            center: 'title',
            right: right
        },
        views: {
            dayGridMonth : {
                titleFormat: {
                    year: '2-digit', month: '2-digit', day: '2-digit'
                }
            },
            listWeek: {
                titleFormat: {
                    year: '2-digit', month: '2-digit', day: '2-digit'
                }
            },
            timeGridWeek: {
                titleFormat: {
                    year: '2-digit', month: '2-digit', day: '2-digit'
                }
            },
            timeGridDay: {
                titleFormat: {
                    year: '2-digit', month: '2-digit', day: '2-digit'
                }
            }
        },
        height: "auto",
        slotMinTime: slotMin,
        slotMaxTime: slotMax,
        slotDuration: cal.duration,
        initialView: initView,
        weekNumbers: true,
        allDaySlot: false,
        plugins: [
            dayGridPlugin,
            timeGridPlugin,
            listPlugin,
            interactionPlugin,
            // resourceTimelinePlugin,
            moment,
            rrulePlugin
        ],
        droppable: drop,
        selectable: select,
        editable: edit,
        events: events,
        navLinks: true,
        weekText: '',
        eventClick: function(info) {
            // console.log('info.event.start', info.event.start.toDateString() + ' ' + info.event.start.toTimeString().split(' ')[0]);
            // console.log('info.event.end', info.event.end.toDateString() + ' ' + info.event.end.toTimeString().split(' ')[0]);
            let start = info.event.start.toDateString().split(' ')[0];
            start = start + ' ' + info.event.start.toDateString().split(' ')[1];
            start = start + ' ' + info.event.start.toDateString().split(' ')[2];
            let end = info.event.end.toDateString().split(' ')[0];
            end = end + ' ' + info.event.end.toDateString().split(' ')[1];
            end = end + ' ' + info.event.end.toDateString().split(' ')[2];
            // console.log('start split', start);
            $('#shiftEmployee').html(info.event.title);
            $('#shiftMember').html(info.event.extendedProps.extendedProp.member_id);
            $('#shiftRawStart').html(info.event.start);
            $('#shiftRawEnd').html(info.event.end);
            $('#shiftStart').html(info.event.start.toDateString() + ' ' + info.event.start.toTimeString().split(' ')[0]);
            $('#shiftEnd').html(info.event.end.toDateString() + ' ' + info.event.end.toTimeString().split(' ')[0]);
            $('#shiftId').html(info.event.extendedProps.extendedProp.shift_id);
            $('#shiftStation').html(info.event.extendedProps.extendedProp.station);
            $('#shiftNotes').html(info.event.extendedProps.extendedProp.notes);
            $('#shiftBgcolor').html(info.event.extendedProps.extendedProp.bgcolor);
            $('#shiftTextColor').html(info.event.extendedProps.extendedProp.textColor);
            $('#eventId').html(info.event.extendedProps.extendedProp.id);
            $('#recurring').html(info.event.extendedProps.extendedProp.recurring);
            $('#start_recur').html(info.event.extendedProps.extendedProp.startRecur);
            $('#end_recur').html(info.event.extendedProps.extendedProp.endRecur);
            $('#on_sunday').html(info.event.extendedProps.extendedProp.on_sunday);
            $('#on_monday').html(info.event.extendedProps.extendedProp.on_monday);
            $('#on_tuesday').html(info.event.extendedProps.extendedProp.on_tuesday);
            $('#on_wednesday').html(info.event.extendedProps.extendedProp.on_wednesday);
            $('#on_thursday').html(info.event.extendedProps.extendedProp.on_thursday);
            $('#on_friday').html(info.event.extendedProps.extendedProp.on_friday);
            $('#on_saturday').html(info.event.extendedProps.extendedProp.on_saturday);
            $('#shiftCalendar').html(cal.id);
            $('#shiftCalSlug').html(cal.slug);
            // console.log('data identified');
            $('#calendarModal').modal('show');
        },
        dateClick: function(info) {
            console.log('Clicked on: ' + info.dateStr);
            console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            console.log('Current view: ' + info.view.type);
            console.log('info',info);
            console.log('info.view',info.view);
            // change the day's background color just for fun
            // info.dayEl.style.backgroundColor = 'red';
            // calendar.gotoDate(info.date);
        },
        weekNumberCalculation: function(weekStart) {
            $('#weekStart').val(weekStart);
            return '';
        },
        navLinkWeekClick: function(weekStart) {
            console.log('week link start', weekStart);
        }

    });
    if(gotoDate && window.location.pathname === '/home') {
        calendar.gotoDate(gotoDate);
    }

    calendar.render();
}

export { TNGcal };
