import { TNGcal } from './tngCal';

$(function() {
    let ga = window.ga;
    let moment = require('moment');

    $(window).ready(function(e) {
        let pts = window.location.pathname.split('/');
        if (pts[1] === 'calendar') {
            $('#viewerModal').show();
        } else if (pts[1] === 'calendars' && pts[3] === 'viewer') {
            let slug = $('#calendar_' + pts[2]);
            slug.click();
        }

        if (pts[1] === 'home') {
            return axios.get('calendars')
                .then(response => {
                    if (response.data.calendars.length) {
                        $('#calendar_' + response.data.calendars[0].slug).click();
                    }
                });
        }

    });

    $(window).scroll(function(e) {
        let winY = $(window).scrollTop();
        // console.log('winY', winY);
        let accShifts = document.getElementById('shifts-index');
        let height = $('#header').height();
        let mt = winY - height;
        if (mt <= 0) {
            accShifts.setAttribute('style', 'margin-top:0px;width:20%');
        } else {
            accShifts.setAttribute('style', 'margin-top:' + mt + 'px; width: 20%');
        }
    });

    $('#saveShift').on('click', function(e) {
        e.preventDefault();

        let url = 'events/save';
        let recurring = $('#recurring').html();
        let data = {
            _token: Laravel.csrfToken,
            calendar_id: $('#shiftCalendar').html(),
            member_id: $('#shiftMember').html(),
            shift_id: $('#shiftId').html(),
            employee: $('#shiftEmployee').html(),
            // station: $('#shiftStation').html(),
            // notes: $('#shiftNotes').html(),
            // bgcolor: $('#shiftBgcolor').html(),
            // textcolor: $('#shiftTextColor').html(),
            start: new Date($('#shiftRawStart').html()).toISOString(),
            end: new Date($('#shiftRawEnd').html()).toISOString(),
        };
        // console.log('start', data.start);
        // let sd = new Date($('#shiftRawStart').html()).toISOString();
        // console.log('sd', sd);
        if (data.member_id.length !== 36) {
            console.log('need endpoint to get member_id from Member.name');
        }
        // console.log('recurring', recurring);
        // console.log('data',data);
        ga('send', 'event', 'Event', 'create', data.notes);
            $.post(url, data, function (response) {
                // console.log(data);
                // console.log('response',response);
                $('#calendar_' + $('#shiftCalSlug').html()).click();
            });
        // } else {
        //     data.id = eventId;
        //     $.post('events/' + eventId + '/update', data, function(response) {
        //
        //     });
        // }
    });

    $('#deleteShift').on('click', function(e) {
        e.preventDefault();

        let id = $('#eventId').html();
        let url = 'events/' + id + '/drop';
        let data = {
            _token: Laravel.csrfToken,
            id: id
        };
        $.post(url, data, function(response) {
            // console.log('response:',response);
            $('#calendar_' + $('#shiftCalSlug').html()).click();
            $('#calendarModal').modal('hide');
        });
    })

    $('[id^="calendar_"]').on('click', function (e) {
        e.preventDefault();

        let slug = this.id.split('_')[1];

        $('#calendarSlug').val(slug);

        let url = '/calendar/' + slug + '/show';
        let data = {
            slug: slug,
            _token: Laravel.csrfToken
        };

        $.post(url, data, function (response, status) {
            $('#cal-link').html('<a target="_blank" href="' +
                window.location.origin + '/calendar/' + response[2].slug + '/' + slug +
                '">' + window.location.origin + '/calendar/' + response[2].slug + '/' + slug + '</a>'
            );

            let evts = [];
            $.each(response[1], function(indx, evt) {
                if (1 > evt.shift.recurring) {
                    evts[indx] = {
                        start: evt.start,
                        end: evt.end,
                        title: evt.employee,
                        backgroundColor: evt.member.bgcolor,
                        textcolor: evt.member.textcolor,
                        extendedProp: {
                            station: evt.shift.station,
                            notes: evt.shift.notes,
                            shift_id: evt.shift_id,
                            id: evt.id
                        }
                    };
                } else {
                    let days = [];
                    if (evt.shift.on_sunday) days.push('0');
                    if (evt.shift.on_monday) days.push('1');
                    if (evt.shift.on_tuesday) days.push('2');
                    if (evt.shift.on_wednesday) days.push('3');
                    if (evt.shift.on_thursday) days.push('4');
                    if (evt.shift.on_friday) days.push('5');
                    if (evt.shift.on_saturday) days.push('6');

                    let start = new Date(moment(evt.start).unix() * 1000);
                    let end = new Date(moment(evt.end).unix() * 1000);
                    let startTime = ('0' + start.getHours()).slice(-2) + ':' + ('0' + start.getMinutes()).slice(-2);
                    let endTime = ('0' + end.getHours()).slice(-2) + ':' + ('0' + end.getMinutes()).slice(-2);

                    if (end.getDate() > start.getDate()) {
                        let sthr = parseInt(startTime.split(':')[0]) + parseInt(evt.shift.duration.split(':')[0]);
                        let stmin = parseInt(startTime.split(':')[1]) + parseInt(evt.shift.duration.split(':')[1]);
                        endTime = ('0' + sthr).slice(-2) + ':' + ('0' + stmin).slice(-2);
                    }

                    evts[indx] = {
                        allDay: false,
                        title: evt.employee,
                        daysOfWeek: days,
                        startTime: startTime,
                        endTime: endTime,
                        color: evt.member.bgcolor,
                        textcolor: evt.member.textcolor,
                        startRecur: evt.shift.start_recur,
                        endRecur: evt.shift.end_recur,
                        extendedProp: {
                            shift_id: evt.shift_id,
                            station: evt.shift.station,
                            notes: evt.shift.notes,
                            id: evt.id
                        }
                    };
                }
            });

            ga('send', 'event', 'Calendar', 'show', response[0].name);


            $('#calendarHolder').removeClass('d-none');

            TNGcal.showCalendar(response[0], evts, response[3]);

        });
    });

});
